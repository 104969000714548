/**
version=0.15
如何设置页面逻辑，请查阅wide手册: http://www.wware.org/wide/indexw3c.html
**/

(function() {

  function custom_init($) {
    // 此处为自定义处理代码
  }

  function event_init($) { // 事件逻辑定义
    /*INSBEGIN:EVENTINIT*/

    /*INSEND:EVENTINIT*/
  } // 事件逻辑定义结束

  function binding_init($) { // 复合信息点定义
    return /*INSBEGIN:COMPUTED*/ {

    } /*INSEND:COMPUTED*/ ;
  } // 复合信息点定义结束

  function subscribe_init($) { // 信息点变化定义
    return /*INSBEGIN:SUBSCRIBE*/ {

    } /*INSEND:SUBSCRIBE*/ ;
  } // 信息点变化定义结束

  function named_functions($) { // 命名函数定义
    return /*INSBEGIN:NAMEDFUNC*/ {

    } /*INSEND:NAMEDFUNC*/ ;
  } // 命名函数定义结束


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // 下方代码自动生成，如不清晰，请勿修改
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  requirejs.config(
    /*INSPOINTBEGIN:REQUIREJS_CFG*/
    {
      "baseUrl": "/",
      "paths": {
        "jquery": "libs/jquery/dist/jquery.min",
        "css": "libs/require-css/css.min",
        "image": "libs/requirejs-plugins/src/image",
        "bootstrap": "libs/bootstrap/dist/js/bootstrap.min"
      },
      "shim": {
        "bootstrap": {
          "deps": ["jquery"]
        }
      }
    }
    /*INSPOINTEND:REQUIREJS_CFG*/
  );

  require(["jquery", "bootstrap", "libs/wware/wwclass.min"],
    function($, bootstrap, wwclass) {

      window.jQuery = window.$ = $;

      $.wwPageconfig = /*INSPOINTEBEGIN:PAGECONFIG*/ {} /*INSPOINTEEND:PAGECONFIG*/ ;

      $().ready(function() {

        if (!$.wwclass) {
          wwclass.init($);
          $.wwcbi = binding_init($);
          $.wwnf = named_functions($);
          $.wwst = subscribe_init($);
        }

        $.wwclass.process($("body"));

        event_init($);
        custom_init($);
      });
    });
})();
